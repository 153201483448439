* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.layout {
  display: grid;
  grid-template-columns: 200px 1fr;
}

.sidebar {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #011638;
  height: 100%;
}

.sidebar a {
  margin-top: 5px;
  text-decoration: none;
  padding: 10px;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
}

.active {
  background-color: #b9d6f2;
  border-radius: 5px;
  color: black !important;
}

.content {
  padding: 20px;
  background-color: #b9d6f2;
  background: url('./Assets/Backgrounds/bg.jpg');
  height: 93.3vh;
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
}